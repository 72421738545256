.nav {
  @apply relative;
}
.nav::after {
  @apply absolute -bottom-[5px] left-0 h-[4px] w-[0%] rounded-md bg-textYellow duration-200 content-[''];
}
.nav:hover::after {
  @apply w-[100%];
}
.activeNav {
  @apply relative;
}
.activeNav::after {
  @apply absolute -bottom-[5px] left-0 h-[4px] w-[100%] rounded-md bg-textYellow duration-200 content-[''];
}
.dropdown-container::before {
  content: "";
  position: absolute;
  top: -10px; 
  left: 65%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #F9CF79; 
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}