/* @import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Monteci:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Noto+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

.font-jost{
  font-family:'Jost',sans-serif ;
}
.font-Poppins {
  font-family: 'poppins',sans-serif;
}
.font-noto {
font-family: 'Noto Serif', serif;
}

.font-kurale {
  font-family: 'Kurale', serif;
}

.font-raleway {
  font-family: 'Raleway', sans-serif;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}
.monteci {
  font-family: "Monteci";
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Helvetica Neue";
  src:
    local("Helvetica Neue"),
    url("./Fonts/Helvetica Neue LTW0697BlkCnObl.otf") format("opentype");
  font-weight: 400;
}

.playFair {
  font-family: "Playfair Display";
  font-weight: 400;
}
.font-helvetica {
  font-family: "Helvetica Neue";
}
.cinzel {
  font-family: "Cinzel Decorative";
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}
