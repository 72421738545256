
.imgBg{
    background-image:url('../../Assets/weddingfest.png');
    width:100%;
    height: 50px;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: center;
    animation:moveRightToLeft 10s linear infinite;
}

@keyframes moveRightToLeft {
  from {
    background-position: 100% 0; /* Starting position at the right */
  }
  to {
    background-position: 0 0; /* Ending position at the left */
  }
}

.register-left-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Assets/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.3; 
}



.register-right-container {
  position: relative;
}

.register-right-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../Assets/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.1;
  z-index: -5;
}
