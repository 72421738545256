.about_container {
    background-image: url("../../Assets/aboutlogo1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }
  .text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
