@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");

.berkshire {
  font-family: "Berkshire Swash", serif;
}

.landingPage {
  font-family: "Roboto", sans-serif;
}

.hero {
  /* background-image: url("../../Assets//hero-bg.JPG"); */
  /* background-image: url("../../Assets//homeimg.jpeg"); */
  background-image: url("../../Assets//guru.jpeg");
  width: 100%;
  height:80vh;
  background-position: center; 
  background-size: cover;
  background-repeat: no-repeat;
}


.heroContent {
  position: absolute;
  top: 24%;
  left: 6%;
}

#about,
#testimonials {
  position: relative;
  z-index: 1;
}
#home {
  position: relative;
  z-index: 1;
}
#home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../Assets/bg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: -1;
}

#about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../Assets/bg.png");
  /* background-position: center; */
  /* background-size: contain; */
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: -1;
}

#testimonials:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../Assets/bg.png");
  /* background-position: center; */
  /* background-size: cover; */
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: -1;
  transform: scaleX(-1);
}

.content {
  position: relative;
  z-index: 2;
}

.contact-input,
.contact-textArea {
  outline: none;
  border: 0.5px solid gray;
  border-radius: 7px;

  background-color: transparent;
}

.animeBg{
    background-image:url('../../Assets/weddingfest.png');
    width:100%;
    height: 50px;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: center;
    animation:moveRightToLeft 25s linear infinite;
}

.custom-swiper .swiper-slide {
  opacity: 0.3;
  transform: scale(0.8); 
  transition: transform 0.5s ease, opacity 0.5s ease;
  width: 45%;
}

.custom-swiper .swiper-slide-active {
  transform: scale(1.2); 
  opacity: 1;
  z-index: 50; 
  width: 70%; 
}

.custom-swiper .swiper-slide-next,
.custom-swiper .swiper-slide-prev {
  transform: scale(0.9); 
  opacity: 0.3; 
  width: 45%; 
}
/* Customize pagination dots */
.custom-pagination .swiper-pagination-bullet {
  background-color: #444444; 
  transition: background-color 0.3s ease;
}

.custom-pagination .swiper-pagination-bullet-active {
  background-color: #f8c031; 
}

.custom-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.custom-pagination .swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
}
